import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";
import whatispcp from "../../../assets/whatispcp.png";
import thumb from "../../../assets/thumbup.svg";
import shield from "../../../assets/shieldtick.svg";
import money from "../../../assets/moneybag.svg";
function OurSolution() {
    return (
        <div className={`${styles.ourSolution} ${responsiveStyles.ourSolution}  `}>
            <Wrapper>
                <div className={`${styles.ourSolutionInner} ${responsiveStyles.ourSolutionInner}`}>
                    <div className={`${styles.textContent}`}>
                        <h2>Our Solution</h2>
                        <p>Claim to receive up to £10,000 in compensation!</p>
                        <div className={`${styles.threeSteps} ${responsiveStyles.threeSteps}`}>
                            <div>
                                <img src={thumb} />
                                <p>
                                    <span>1</span>Submit Your Claim
                                </p>
                            </div>
                            <div>
                                <img src={shield} />

                                <p>
                                    <span>2</span>Qualification Approved
                                </p>
                            </div>
                            <div>
                                <img src={money} />
                                <p>
                                    <span>3</span>Receive Compensation
                                </p>
                            </div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <CtaButton text="Start Claim" />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

export default OurSolution;
