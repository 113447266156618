import React, { useEffect, useState } from "react";
// import styles from "./styles.module.scss";
import { Report } from "notiflix/build/notiflix-report-aio";

import { titleData } from "../../../data/titles";
import { contactPreferencesData } from "../../../data/contactPreferences";
import { InputFieldText, InputFieldSelect, FieldGroup, Field, SubmitButton, ButtonContainer, FieldContainer, FormCard } from "../../reusable";

import { FormPartInterface, SaveValueInterface } from "../../../interfaces";
import { Checkbox } from "../../reusable/checkbox";
import { InputFieldAddressLookup } from "../../reusable/inputFieldAddressLookup";
import { lenderData } from "../../../data/lenders";
import { SignaturePad } from "../../reusable/sigPad";
import { sectionValidation } from "../../../helpers";
import Previewer from "../../reusable/previewer";
import { useClaimData } from "../../../claimProvider";
import { browserName, osName, deviceDetect, getUA } from "react-device-detect";
import { Loading } from "notiflix";

export const SignatureCCD = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [postCurrentStep, setPostCurrentStep] = useState(0);
    const [signed, setSigned] = useState(false);
    const [stepData, setStepData] = useState<any>({});
    const claim = useClaimData();

    useEffect(() => {
        console.log(".....", isActive);
        // if (isActive == "signature") {
        //     Loading.standard("");
        //     // if (claim && claim.sigLinkData) Loading.remove();
        // }
    }, [claim]);

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        const saved = saveValueFunct(dataToSave);
        let tempStepData = stepData;
        tempStepData[dataToSave.field] = dataToSave.value;
        setStepData(tempStepData);
        return saved;
    };

    const animateBeforeAssign = (assignment: string) => {
        console.log(stepData);
        if (!sectionValidation(stepData, "signature").success) return;
        setTimeout(() => {
            assignGroup(assignment);
        }, 400);
    };

    const getCurrentStep = () => {
        return isActive == "signature" ? (
            <FieldGroup title="Sign Your Letter of Authority">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <Checkbox
                        fieldName={"acceptTerms"}
                        label={
                            "I agree that I have read, understand and accept the Proaxis Legal Ltd terms of business and that the check is free. I also agree to instruct Proaxis Legal Ltd to send a data subject access request (DSAR) to find out if my PCP or HP agreements were mis-sold."
                        }
                        saveValue={preSaveValueFunct}
                    />
                    <br />
                    {/* <Previewer url={""} /> */}
                    {claim?.loaUrl ? <Previewer url={claim.loaUrl} documentString={"LOA"} /> : <></>}
                    {claim?.sigLinkData && (
                        <SignaturePad
                            extraPayload={{ browser: browserName, device: osName, type: "ccdSign", token: claim.sigLinkData.token }}
                            additionalAction={() => animateBeforeAssign(claim?.isResuming ? "complete" : "contactPreferences")}
                            disclaimer={""}
                        />
                    )}
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        );
    };

    return getCurrentStep();
};
