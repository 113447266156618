import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";
import whatispcp from "../../../assets/whatispcp.png";
import thumb from "../../../assets/thumbup.svg";
import shield from "../../../assets/shieldtick.svg";
import money from "../../../assets/moneybag.svg";
import Testimonial from "../../reusable/testimonial";

import img1 from "../../../assets/clients/kate.png";
import img2 from "../../../assets/clients/kayleigh.png";
import img3 from "../../../assets/clients/matt.png";
import img4 from "../../../assets/clients/adam.png";
import img5 from "../../../assets/clients/sam.png";
import img6 from "../../../assets/clients/mellissa.png";

function OurClients() {
    const testimonialData = [
        {
            text: "Probably the most memorable experience I've ever had. MisSold's team was very knowledgeable about the entire process, answered all of my questions, and the amount I was compensated with was completely unexpected. Thank you so much for all of your assistance.",
            title: "Approved Claim",
            amount: "£12,200*",
            author: "Kate",
            location: "Swansea",
            img: img1,
            positionTop: true,
        },
        {
            text: "Everyone was extremely pleasant and enthusiastic about providing excellent service. They guided me through the entire process, which was far less difficult than I had anticipated. Anthony, thank you so much!",
            title: "Approved Claim",
            amount: "£9,200*",
            author: "Kayleigh",
            location: "Wigan",
            img: img2,
            positionTop: false,
        },
        {
            text: "Everyone was very friendly and dedicated to providing excellent service. They assisted me at every step of the way, and it was much easier than I had anticipated.",
            title: "Approved Claim",
            amount: "£7,100*",
            author: "Matt",
            location: "London",
            img: img3,
            positionTop: true,
        },
        {
            text: "I was initially skeptical about making a claim, but MisSold made the process incredibly easy and stress-free. Their team was very helpful and professional, and I was pleasantly surprised by the amount of compensation I received. I would definitely recommend their services to anyone who thinks they may have been mis-sold.",
            title: "Approved Claim",
            amount: "£14,500*",
            author: "Adam",
            location: "Birmingham",
            img: img4,
            positionTop: true,
        },
        {
            text: "I had a fantastic experience working with MisSold. Their team was very knowledgeable and made sure I understood every step of the process. They handled everything on my behalf, and I was thrilled with the outcome. I would definitely use their services again if needed.",
            title: "Approved Claim",
            amount: "£4,800*",
            author: "Sam",
            location: "Manchester",
            img: img5,
            positionTop: false,
        },
        {
            text: "I can't thank MisSold enough for their help. I was feeling quite overwhelmed by the claims process, but their team made everything easy to understand and took care of everything for me. The amount of compensation I received was beyond my expectations. I highly recommend their services to anyone who needs help with a claim.",
            title: "Approved Claim",
            amount: "£6,400*",
            author: "Mellissa",
            location: "Stafford",
            img: img6,
            positionTop: true,
        },
    ];

    return (
        <div className={`${styles.ourClients} ${responsiveStyles.ourClients}  `}>
            <Wrapper>
                <div className={`${styles.ourClientsInner} ${responsiveStyles.ourClientsInner}`}>
                    <h2>Our Beloved Clients</h2>
                    <p>
                        Our clients' average claim value is in excess of £6000* (this depends on what your interest rate was and how long you were
                        paying for the finance)
                    </p>
                    <div className={`${styles.clients} ${responsiveStyles.clients}`}>
                        {testimonialData.map((td: any) => {
                            return (
                                <Testimonial
                                    text={td.text}
                                    title={td.title}
                                    amount={td.amount}
                                    location={td.location}
                                    author={td.author}
                                    img={td.img}
                                    positionTop={td.positionTop}
                                />
                            );
                        })}
                    </div>
                    <p>
                        * These figures are based off estimated quanta calculated off information provided within the finance agreement and the data
                        subject access request. These figures were correct at time of release of this site.
                    </p>
                </div>
            </Wrapper>
        </div>
    );
}

export default OurClients;
