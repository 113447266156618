import { useState } from "react";
import styles from "./styles.module.scss";
import { FieldGroup, FormCard } from "../../reusable";

import { FormPartInterface } from "../../../interfaces";
export const CompleteSigned = (props: FormPartInterface) => {
    const { isActive } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [postCurrentStep, setPostCurrentStep] = useState(0);

    const getCurrentStep = () => {
        return isActive == "complete" ? (
            <FieldGroup title="Claim Complete">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <h2 style={{ textAlign: "center" }}>Thank you!</h2>
                    <p className={styles.tagline}>
                        Your claim documents have been signed.
                        <br /> <br /> A member of the Car Claims Direct team will be in touch soon.
                    </p>
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        );
    };

    return getCurrentStep();
};
