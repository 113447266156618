import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";
import benefitsCar from "../../../assets/benefits-car.png";

import img1 from "../../../assets/benefits1.svg";
import img2 from "../../../assets/benefits2.svg";
import img3 from "../../../assets/benefits3.svg";
import img4 from "../../../assets/benefits4.svg";

function Benefits() {
    return (
        <div className={`${styles.benefits} ${responsiveStyles.benefits}  `}>
            <Wrapper>
                <div className={`${styles.benefitsInner} ${responsiveStyles.benefitsInner}`}>
                    <h2>Benefits</h2>
                    <p>Claim now if you've had a car on finance in the past 15 years?</p>

                    <div className={`${styles.fourBenefits} ${responsiveStyles.fourBenefits}`}>
                        <div>
                            <div className={`${styles.imageContainer} ${responsiveStyles.imageContainer}`}>
                                <img src={img1} />
                            </div>
                            <p>Claim for Current & Past Vehicles</p>
                        </div>
                        <div>
                            <div className={`${styles.imageContainer} ${responsiveStyles.imageContainer}`}>
                                <img src={img2} />
                            </div>
                            <p>FCA Regulated</p>
                        </div>
                        <div>
                            <div className={`${styles.imageContainer} ${responsiveStyles.imageContainer}`}>
                                <img src={img3} />
                            </div>
                            <p>Panel of Finance Claim Experts</p>
                        </div>
                        <div>
                            <div className={`${styles.imageContainer} ${responsiveStyles.imageContainer}`}>
                                <img src={img4} />
                            </div>
                            <p>No Win, No Fee</p>
                        </div>
                    </div>
                </div>
            </Wrapper>
            <div className={`${styles.carWithCta} ${responsiveStyles.carWithCta}`}>
                <img src={benefitsCar} />
                <CtaButton text="Start Claim" />
            </div>
        </div>
    );
}

export default Benefits;
