import styles from "./styles.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import logo from "../../../../../assets/carclaimslogo.png";
import { Wrapper } from "../../../../reusable";
import { Link } from "react-router-dom";

export function CCDFooter() {
    return (
        <div className={`${styles.footer} ${responsiveStyles.footer}`}>
            <Wrapper>
                <div className={`${styles.innerFooter} ${responsiveStyles.innerFooter}`}>
                    <div>
                        <nav>
                            <ul>
                                <li>
                                    <Link to={"/"}>Home </Link>
                                </li>
                                <li>
                                    <Link to={"/contact"}>Contact </Link>
                                </li>
                                <li>
                                    <Link to={"/claim/start"}>Start Claim</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div>
                        <p>
                            www.missold-finance.co.uk is a website and trading name operated by Proaxis Legal Ltd, a Claims Management Company
                            authorised and regulated by the Financial Conduct Authority, reference number 833988. Proaxis Legal Ltd is a private
                            limited company, registered in England and Wales under Company number 4494454. VAT number 800564657, registered address is
                            Portal Business Centre, Bridgewater House, North Road, Ellesmere Port, Cheshire, CH65 1AF. Proaxis Legal Ltd does not
                            charge customers for their service and in return for passing customer details to a Solicitor Proaxis Legal Ltd receive a
                            fee for their marketing services. If successful in any claim for compensation and by way of deduction the solicitor will
                            charge a percentage of any compensation awarded that will have been discussed and agreed before starting your case. There
                            are no hidden or up-front costs and if any claim is unsuccessful no charge will be made.
                        </p>

                        <hr />
                        <div className={styles.footerbottom}>
                            <div className={styles.logoContainer}>
                                <p>© {new Date().getFullYear()} MisSold | Argentum47, Inc.</p>
                                <img src={logo} />
                            </div>
                            <div className={`${styles.terms} ${responsiveStyles.terms}`}>
                                <p>
                                    <a href="../../../../documents/terms.pdf" target="__blank">
                                        Terms and Conditions
                                    </a>
                                </p>
                                <p>
                                    <a href="../../../../documents/privacy-policy.pdf" target="__blank">
                                        Privacy Policy
                                    </a>
                                </p>
                                <p>
                                    <a href="../../../../documents/nowinnofee.pdf" target="__blank">
                                        No Win, No Fee Policy
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}
