import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

import closeIcon from "../../../assets/close-icon.svg";

const questions = [
    {
        q: "What is PCP or HP?",
        a: (
            <p>
                The two most common types of car finance are Hire Purchase (HP) and Personal Contract Purchase (PCP). Both types of vehicle finance
                typically involve you paying a deposit and then paying off a fixed amount over a set amount of time, and often incur interest on top
                of what you have borrowed.
            </p>
        ),
    },
    {
        q: "Who can claim?",
        a: <p>Anyone who has taken out a PCP or HP contract in the last ten years can pursue a claim.</p>,
    },
    {
        q: "What do we do for you?",
        a: (
            <p>
                We will request information on your behalf from your finance provider and complete a comprehensive review of any agreements you have
                taken out. If we find evidence that you may have been mis-sold we will calculate an estimated loss on your behalf and come back to you
                with our findings.
            </p>
        ),
    },
    {
        q: "How long does a claim take?",
        a: (
            <p>
                If we believe there is sufficient grounds to make a claim against your finance provider we can recommend your case be passed across to
                one of our panel of expert law firms, as these cases can require court litigation they will help guide you through the process on a
                No-Win, No-Fee Basis.
            </p>
        ),
    },
    {
        q: "Can I claim for more than one PCP or HP contract?",
        a: <p>Yes, you can claim for any PCP or HP agreements you've had in the past ten years.</p>,
    },
    {
        q: "How much compensation will I receive?",
        a: (
            <p>
                An FCA investigation revealed that the average customer on a £10,000 PCP agreement over four years has been overcharged by more than
                £1,100.
                <br />
                <br />
                The amount you get back if you were mis-sold depends on: the the amount you were paying, the length of the contract, and also the APR
                charged within the agreement.
            </p>
        ),
    },
    {
        q: "My PCP/HP agreement has finished - can I still claim?",
        a: <p>Yes, you can.</p>,
    },
    {
        q: "Have I been Mis-sold car finance?",
        a: (
            <p>
                It’s fair to say that the UK has a passion for cars. A desire to own the latest model leads many people to take out car finance deals.
                These packages (particularly Personal Contract Plans, or PCPs for short) are usually designed to convince the driver that they’re
                getting a great deal – particularly when compared to hire-purchase packages. The problem is, on many occasions they were mis-sold to
                the people that agreed to them because their suitability wasn’t properly assessed, or the obligations and risks not properly
                explained.
            </p>
        ),
    },
    {
        q: "Am I owed compensation for Mis-sold car finance?",
        a: (
            <>
                <p>Some examples of circumstances where you might have been mis-sold a car finance package include:</p>
                <ul>
                    <li>The salesperson didn't properly explain the finance deal to you.</li>
                    <li>You weren't presented with a range of options.</li>
                    <li>It wasn't made clear who is financially responsible for repairs to the vehicle.</li>
                    <li>
                        The dealership didn't outline who actually owns the vehicle (in some cases, this is a third-party hire purchase business, not
                        the car dealership).
                    </li>
                    <li>The salesperson failed to outline the interest charges properly.</li>
                    <li>You were not informed of undisclosed commissions.</li>
                </ul>
            </>
        ),
    },
    {
        q: "I want to claim for car finance Mis-selling - what happens now?",
        a: (
            <p>
                Although car finance mis-selling is a relatively new scandal, our team understands the marketplace well and is ready start the process
                for you today. Customer care is at the heart of what we do, and to date, we’ve successfully helped our clients get back millions in
                compensation for mis-sold financial products.
                <br />
                <br />
                If you’ve taken out a car finance package simply click one of the "Start Claim" links on this page to send us the details of your
                finance agreement. After running through your situation, we’ll ascertain whether or not you’re in a position to make a claim.
            </p>
        ),
    },
    {
        q: "Do I have to use a claim management agent to represent my claim?",
        a: (
            <p>
                You are under no obligation to use our service to make a claim for financial compensation with regards to your car finance
                agreement(s). You may make a claim yourself for free against the person you wish to complain about or you can present your claim for
                free to the Financial Ombudsman Service.
            </p>
        ),
    },
    {
        q: "What will it cost me?",
        a: (
            <>
                <p>
                    We do not ask for any upfront fees and unless your claim is successful there will be no charge. If your claim is successful our
                    panel solicitors will deduct 40% + VAT from your awarded compensation. Your solicitor will expressly discuss the proposed
                    deduction from your compensation award and will confirm this in your written agreement. For example and by way of illustration
                    only:
                </p>
                <div className={styles.table}>
                    <p>
                        <span>Compensation recovered</span>
                        <span>
                            Solicitor Fee 40% <br />
                            <br />
                            plus VAT – total 48%
                        </span>
                        <span>You would receive</span>
                    </p>
                    <p>
                        <span>£1000</span>
                        <span>£480</span>
                        <span>£520</span>
                    </p>
                    <p>
                        <span>£3000</span>
                        <span>£1440</span>
                        <span>£1560</span>
                    </p>
                    <p>
                        <span>£10000</span>
                        <span>£4800</span>
                        <span>£5200</span>
                    </p>
                </div>
                <p>
                    Please note: If you cancel after 14 days and we have already submitted your claim, and an offer of compensation is subsequently
                    made, our panel solicitor will charge their full fee as per their T&Cs.
                </p>
            </>
        ),
    },
    {
        q: "What percentange do lawyers take if you win your case?",
        a: (
            <p>
                If your claim is successful, then you’ll receive compensation and can spend it however you choose. You’ll need to pay your solicitor’s
                success fee which is an agreed percentage (40% + VAT) between you and your solicitor. The panel solicitor will expressly discuss this
                with you, agree it and confirm it in a written agreement (Conditional Fee Agreement) at the start of your case so you know what you
                will have to pay.
                <br />
                <br />
                To ensure your claim is completely risk free your solicitor will ensure ATE (after the event) insurance is put in place which is
                designed to cover legal fees, including opposition legal costs should your claim for any reason be unsuccessful. The ATE also covers
                other costs and expenses paid out on your behalf including expert reports, barristers’ fees and court fees. ATE provides an extra
                layer of protection against costs and includes those instances where it is necessary to abandon your case part way through where the
                chances of winning your claim become far lower than first thought.
                <br />
                <br />
                The cost of ATE insurance policy only becomes due if your claim is successful, the fee for which is included within the solicitors
                agreed fee at the start of your case.
            </p>
        ),
    },
];
function Faqs() {
    const toggleFaq = (i: number) => {
        const a = document.getElementById(`answer-${i}`);
        const ac = document.getElementById(`answerContainer-${i}`);
        const img = document.getElementById(`img-${i}`);
        if (a != null && ac != null && img != null) {
            let h = a.getBoundingClientRect().height + 15;
            let o = "1";
            let tr = `rotate(45deg)`;
            if (ac.getBoundingClientRect().height != 0) {
                h = 0;
                o = "0";
                tr = `rotate(0)`;
            }
            ac.style.height = `${h}px`;
            img.style.transform = tr;
            setTimeout(() => {
                ac.style.opacity = o;
            }, 250);
        }
    };

    return (
        <div className={`${styles.faqs} ${responsiveStyles.faqs} faq-elem`}>
            <Wrapper>
                <div className={`${styles.faqsInner} ${responsiveStyles.faqsInner}`}>
                    <h2>FAQs</h2>
                    <div className={`${styles.faqsContainer} ${responsiveStyles.faqsContainer}`}>
                        {questions.map((faq, i) => {
                            return (
                                <div className={styles.qContainer} key={i} onClick={() => toggleFaq(i)}>
                                    <div>
                                        <p>{faq.q}</p>
                                        <img id={`img-${i}`} src={closeIcon} />
                                    </div>
                                    <div id={`answerContainer-${i}`}>
                                        <div id={`answer-${i}`}>{faq.a}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

export default Faqs;
