import styles from "../globals/pages.module.scss";

import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { Wrapper } from "../components/reusable";
import { apiErrors } from "../helpers/apiErrors";
import Hero from "../components/pageComponents/hero";
import KnowAboutPCP from "../components/pageComponents/knowAboutPCP";
import FinanceVideo from "../components/pageComponents/financeVideo";
import WhatIsPCP from "../components/pageComponents/whatIsPcp";
import OurSolution from "../components/pageComponents/ourSolution";
import OurClients from "../components/pageComponents/ourClients";
import Benefits from "../components/pageComponents/benefits";
import LawPartners from "../components/pageComponents/lawPartners";
import DidYouFinance from "../components/pageComponents/didYouFinance";
import ClaimSpecialist from "../components/pageComponents/specialist";
import Faqs from "../components/pageComponents/faqs";
import HaveAClaim from "../components/pageComponents/haveAClaim";

function Home() {
    const [resumeClaimData, setResumeClaimData] = useState<any>();
    const { token } = useParams();
    const [paramsObj, setParamsObj] = useState<any>({});
    const queryParameters = new URLSearchParams(window.location.href);

    const [claimResumed, setClaimResumed] = useState(true);
    const [requestingClaim, setRequestingClaim] = useState(true);

    useEffect(() => {
        if (window.location.href.includes("#faqs")) {
            const faqs = document.getElementsByClassName("faq-elem");
            if (faqs.length > 0) {
                setTimeout(() => {
                    faqs[0].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }, 300);
            }
        }
    }, []);

    useEffect(() => {
        if (!requestingClaim) {
            axios
                .post("", {})
                .then((response) => {
                    const resumeData = response.data;
                    console.log("resumeData:", resumeData);
                    if (resumeData.success) {
                        setResumeClaimData(resumeData);
                        setClaimResumed(true);
                    } else {
                        throw new Error("failed....");
                    }
                    // Loading.remove();
                })
                .catch((e) => {
                    console.log("error:", e);
                    Loading.remove();
                    if (e.response) {
                        const serverError = e.response.data;
                        apiErrors(serverError);
                    } else {
                        apiErrors("unknown");
                    }
                });
        }
    }, [requestingClaim]);

    return (
        <div className={`${styles.pageContainer}`}>
            <Hero />
            <KnowAboutPCP />
            <FinanceVideo />
            <WhatIsPCP />
            <OurSolution />
            <OurClients />
            <Benefits />
            <LawPartners />
            <DidYouFinance />
            <ClaimSpecialist />
            <Faqs />
            <HaveAClaim />
        </div>
    );
}

export default Home;
