import styles from "../globals/pages.module.scss";

import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { apiErrors } from "../helpers/apiErrors";
import ClaimForm from "../components/claimForm/main";
import { browserName, osName, deviceDetect, getUA } from "react-device-detect";

function MakeClaim() {
    const [resumeClaimData, setResumeClaimData] = useState<any>();
    const { token } = useParams();
    const [paramsObj, setParamsObj] = useState<any>({});
    const queryParameters = new URLSearchParams(window.location.href);

    const [claimResumed, setClaimResumed] = useState(true);
    const [requestingClaim, setRequestingClaim] = useState(true);

    // useEffect(() => {
    //     if (!requestingClaim) {
    //         axios
    //             .post("", {})
    //             .then((response) => {
    //                 const resumeData = response.data;
    //                 console.log("resumeData:", resumeData);
    //                 if (resumeData.success) {
    //                     setResumeClaimData(resumeData);
    //                     setClaimResumed(true);
    //                 } else {
    //                     throw new Error("failed....");
    //                 }
    //                 // Loading.remove();
    //             })
    //             .catch((e) => {
    //                 console.log("error:", e);
    //                 Loading.remove();
    //                 if (e.response) {
    //                     const serverError = e.response.data;
    //                     apiErrors(serverError);
    //                 } else {
    //                     apiErrors("unknown");
    //                 }
    //             });
    //     }
    // }, [requestingClaim]);

    return (
        <div className={`${styles.pcpClaimFormContainer}`}>
            <ClaimForm />
        </div>
    );
}

export default MakeClaim;
