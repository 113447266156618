import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import SignatureCanvas from "react-signature-canvas";
import { IsMobile } from "../../layout";
import axios from "axios";
import { SubmitButton } from "..";
import { useParams } from "react-router-dom";
import { log } from "console";

interface sigProps {
    additionalAction?: () => void | null;
    extraPayload?: any;
    disclaimer?: any;
}

export const SignaturePad = ({ extraPayload, additionalAction, disclaimer }: sigProps) => {
    const signCanvas = React.useRef() as React.MutableRefObject<any>;
    const [sigData, setSigData] = useState("");

    const { token } = useParams();

    const saveSign = () => {
        signCanvas.current.on();
        let x = signCanvas.current.toDataURL("image/png");
        setSigData(x);
    };

    const clearSign = (e: React.MouseEvent) => {
        e.preventDefault();
        clearSigPad();
    };

    const clearSigPad = () => {
        signCanvas.current.clear();
        signCanvas.current.on();
        setSigData("");
    };

    const submitSignature = () => {
        if (sigData.length < 1) {
            Report.failure("Please Sign", "Please enter your signature onto the signature pad to continue.", "OK");
            return;
        }

        Loading.circle("Signing Document");
        let payload = {
            token,
            type: "",
            sigData: sigData,
        };

        if (extraPayload && Object.keys(extraPayload).length) {
            // console.log("extra payload: ", extraPayload);
            // console.log("extra payload: ", Object.keys(extraPayload).length);
            payload = { ...payload, ...extraPayload };
        }

        axios
            .post(`${process.env.REACT_APP_API_SIGN_URL}`, payload)
            .then(function (response) {
                console.log(response);
                Loading.remove();
                if (response.data !== "SUCCESS") {
                    Report.failure(
                        "An Error Occurred",
                        "We have encounterred an internal error, please try again later. If the problem persists please contact info@carclaimsdirect.co.uk",
                        "Okay"
                    );
                    return;
                }
                // clearSigPad();
                if (additionalAction) additionalAction();
            })
            .catch(function (error) {
                console.log(error);
                Loading.remove();
                Report.failure(
                    "An Error Occurred",
                    "We have encounterred an internal error, please try again later. If the problem persists please contact support@carclaimsdirect.co.uk ",
                    "Okay"
                );
                // if (additionalAction) additionalAction();
            });
        // Loading.remove();
        // if (additionalAction) additionalAction();
    };

    //<Previewer url={preview} />;
    return (
        <div className={styles.signaturePadContent}>
            <div className={`${styles.sigPadContainer}  `}>
                <p>Please use your mouse, finger or stylus to draw your signature below.</p>
                <div>
                    <SignatureCanvas
                        ref={signCanvas}
                        onEnd={saveSign}
                        clearOnResize={false}
                        penColor="grey"
                        canvasProps={{ width: window.innerWidth < 760 ? 350 : 760, height: 300, className: "sigCanvas" }}
                    />
                    <span onClick={clearSign}>Clear</span>
                </div>
            </div>
            {disclaimer && <p className={styles.signatureDisclaimer}>{disclaimer}</p>}
            <div className={styles.buttonContainer}>
                <SubmitButton text="Submit" btnType="primary" onClick={submitSignature} />
            </div>
        </div>
    );
};
