import styles from "./styles.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import logo from "../../../../../assets/carclaimslogo.png";
import { Wrapper } from "../../../../reusable";
import { Link } from "react-router-dom";

export function OriginalFooter() {
    return (
        <div className={`${styles.footer} ${responsiveStyles.footer}`}>
            <Wrapper>
                <div className={`${styles.innerFooter} ${responsiveStyles.innerFooter}`}>
                    <div>
                        <nav>
                            <ul>
                                <li>
                                    <Link to={"/"}>Home </Link>
                                </li>
                                <li>
                                    <Link to={"/contact"}>Contact </Link>
                                </li>
                                <li>
                                    <Link to={"/claim/start"}>Start Claim</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div>
                        <p>
                            This site is owned and operated by Olton Alexander Limited which is registered in England and Wales. Registered number:
                            07652082. Registered office: Calcutt Court, Calcutt, Swindon, England, SN6 6JR. Olton Alexander Limited is authorised and
                            regulated by the Financial Conduct Authority in respect of regulated claims management activity. FRN: 833941. Information
                            Commissioner's Office registration number ZB559651.
                        </p>
                        <ul className={styles.links}>
                            <li>
                                <a href="https://www.fca.org.uk/news/press-releases/fca-ban-motor-finance-discretionary-commission-models">
                                    https://www.fca.org.uk/news/press-releases/fca-ban-motor-finance-discretionary-commission-models
                                </a>
                            </li>
                            <li>
                                <a href="https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf">
                                    https://www.fca.org.uk/publication/multi-firm-reviews/our-work-on-motor-finance-final-findings.pdf
                                </a>
                            </li>
                        </ul>
                        <hr />
                        <div className={styles.footerbottom}>
                            <div className={styles.logoContainer}>
                                <p>© {new Date().getFullYear()} MisSold | Argentum47, Inc.</p>
                                <img src={logo} />
                            </div>
                            <div className={`${styles.terms} ${responsiveStyles.terms}`}>
                                <p>
                                    <a href="/documents/terms.pdf" target="__blank">
                                        Terms and Conditions
                                    </a>
                                </p>
                                <p>
                                    <a href="/documents/privacy-policy.pdf" target="__blank">
                                        Privacy Policy
                                    </a>
                                </p>
                                {/* <p>
                                    <a href="../../../../documents/nowinnofee.pdf" target="__blank">
                                        No Win, No Fee Policy
                                    </a>
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}
