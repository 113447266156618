import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, SubmitButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

import heroimg from "../../../assets/car-hero.png";
import tagTick from "../../../assets/tag-tick.svg";
import lines from "../../../assets/lines.svg";
import ClaimForm from "../../claimForm/main";
import { IsMobile } from "../../layout";
import { Link } from "react-router-dom";
function Hero() {
    return (
        <div className={`${styles.hero} ${responsiveStyles.hero}  `}>
            <Wrapper>
                <div className={`${styles.heroContent} ${responsiveStyles.heroContent}`}>
                    <h1>
                        Have You Been Mis-Sold?
                        <br />
                        <span>We Can Help!</span>
                    </h1>
                    <div className={styles.lines}>
                        <img src={lines} />
                    </div>
                    <img src={heroimg} />
                    <div className={`${styles.tagLine} ${responsiveStyles.tagLine}`}>
                        <div>
                            <img src={tagTick} />
                            <p>Free no obligation check!</p>
                        </div>
                        <div>
                            <img src={tagTick} />
                            <p>Easy and hassle free!</p>
                        </div>
                        <div>
                            <img src={tagTick} />
                            <p>You could be owed £1000s</p>
                        </div>
                    </div>
                    {IsMobile() ? <CtaButton text="Make A Claim" /> : <></>}
                    <ul>
                        <li>What is PCP?</li>
                        <li>Our Benefits</li>
                        <li>Our Solutions</li>
                        <li>3 Easy Steps</li>
                        <li>Claim Now!</li>
                    </ul>
                </div>
            </Wrapper>
        </div>
    );
}

export default Hero;
