import { useEffect, useState } from "react";

import styles from "./styles.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import logo from "../../../assets/logo.png";
import { Wrapper } from "../../reusable";
import { OriginalFooter } from "./footerTypes";
import { CCDFooter } from "./footerTypes/ccd";

function Footer() {
    const [footerType, setFooterType] = useState<String>("");

    useEffect(() => {
        assignFooterType();
    }, []);

    function assignFooterType() {
        const pcs = window.location.href.split("/");
        console.log(pcs[pcs.length - 2]);

        setFooterType(pcs[pcs.length - 2]);
        // setFooterType("start2");
        // setFooterType("start");
    }

    function selectFooter() {
        switch (footerType) {
            case "sign-oa-loa":
                return <OriginalFooter />;
                break;
            case "sign-hyperian-cfa":
                return <CCDFooter />;
                break;
            default:
                return <OriginalFooter />;
                break;
        }
    }
    return selectFooter();
}

export default Footer;
