import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";
import playbutton from "../../../assets/play.svg";
import { useState } from "react";

function FinanceVideo() {
    const [showModal, setShowModal] = useState(false);
    const toggleVideo = () => {
        setShowModal(!showModal);
    };

    return (
        <div className={`${styles.fianceVideo} ${responsiveStyles.fianceVideo}  `}>
            <Wrapper>
                <h2>Financed Your Car?</h2>
                <div className={`${styles.fianceVideoContainer} ${responsiveStyles.fianceVideoContainer}  `}>
                    <div
                        className={`${styles.videoBox} ${responsiveStyles.videoBox}`}
                        onClick={() => {
                            toggleVideo();
                        }}
                    >
                        <div className={`${styles.videobg} ${responsiveStyles.videobg}  `}>
                            <img src={playbutton} />
                        </div>
                    </div>
                    <div className={` ${showModal ? styles.show : ""} ${styles.videoModalContainer} ${responsiveStyles.videoModalContainer}  `}>
                        <button
                            onClick={() => {
                                toggleVideo();
                            }}
                        >
                            x
                        </button>
                        <div className={`${styles.videoContainer} ${responsiveStyles.videoContainer}`}>
                            <iframe id="player" src="https://player.vimeo.com/video/805583486?app_id=122963&amp;h=a79bc6017"></iframe>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

export default FinanceVideo;
