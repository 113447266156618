import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";

import specialist from "../../../assets/specialist.png";

function ClaimSpecialist() {
    return (
        <div className={`${styles.specialist} ${responsiveStyles.specialist}`}>
            <Wrapper>
                <div className={`${styles.specialistInner} ${responsiveStyles.specialistInner}`}>
                    <img src={specialist} />
                    <div>
                        <p>
                            "At MisSold, we believe that everyone deserves justice and compensation for any financial harm they've suffered. Our
                            dedicated team of experts is here to help our clients navigate the complex world of mis-selling and financial
                            compensation."
                        </p>
                        <p>
                            Gary Welburn
                            <span>Claim Specialist</span>
                        </p>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

export default ClaimSpecialist;
