import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";
import whatispcp from "../../../assets/whatispcp.png";
import warning from "../../../assets/warning.svg";
function WhatIsPCP() {
    return (
        <div className={`${styles.whatIsPCP} ${responsiveStyles.whatIsPCP}  `}>
            <Wrapper>
                <div className={`${styles.twoCols} ${responsiveStyles.twoCols}`}>
                    <div className={`${styles.imgContent} ${responsiveStyles.imgContent}`}>
                        <img src={whatispcp} />
                    </div>
                    <div className={`${styles.textContent} ${responsiveStyles.textContent}`}>
                        <h2>What is PCP?</h2>
                        <p>
                            Personal Contract Plans, or PCP are usually designed to convince customers that they're getting a great deal when
                            purchasing a vehicle.
                        </p>
                        <ul>
                            <li>The loan amount is based on the anticipated depreciation of the car</li>
                            <li>Mis-sold car PCP finances have occurred to more than 560,000 people who didn't need it.</li>
                            <li>People like you paid about £300 million more in interest than they should have!</li>
                            <li>Why? These high rates meant more commissions for dealerships.</li>
                        </ul>
                        <CtaButton text="Start Claim" />
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

export default WhatIsPCP;
