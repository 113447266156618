import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Report } from "notiflix/build/notiflix-report-aio";

import { titleData } from "../../../data/titles";
import { contactPreferencesData } from "../../../data/contactPreferences";
import {
    InputFieldText,
    InputFieldSelect,
    FieldGroup,
    Field,
    SubmitButton,
    ButtonContainer,
    FieldContainer,
    FormCard,
    InputFile,
} from "../../reusable";
import { filesArrayInterface } from "../../../interfaces/file";

import { FormPartInterface, SaveValueInterface } from "../../../interfaces";
import { Checkbox } from "../../reusable/checkbox";
import { InputFieldAddressLookup } from "../../reusable/inputFieldAddressLookup";
import { lenderData } from "../../../data/lenders";
import { sectionValidation, uploadFileToServer } from "../../../helpers";
import { useClaimData } from "../../../claimProvider";
import { useParams } from "react-router-dom";

export const UploadDocuments = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props;
    const { token } = useParams();
    const [currentStep, setCurrentStep] = useState(0);
    const [postCurrentStep, setPostCurrentStep] = useState(0);
    const [stepData, setStepData] = useState<any>({});
    const [uploadFiles, setUploadFiles] = useState<boolean>(false);
    const [filesArray, setFilesArray] = useState<Array<filesArrayInterface>>([]);
    const claim = useClaimData();

    // const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
    //     const saved = saveValueFunct(dataToSave);
    //     let tempStepData = stepData;
    //     tempStepData[dataToSave.field] = dataToSave.value;
    //     setStepData(tempStepData);
    //     return saved;
    // };

    const animateBeforeAssign = async (assignment: string) => {
        console.log(stepData);

        if (!sectionValidation(stepData, "files").success) return;
        const usableToken = token ? token : claim?.idUploadData?.token;

        if (usableToken) await uploadFileToServer(usableToken, filesArray);
        setTimeout(() => {
            assignGroup(assignment);
        }, 400);
    };

    const attachFile = (fileData: any) => {
        console.log("file data: ", fileData);
        let tempStepData = stepData;
        tempStepData[fileData.fileInputName] = "yes";
        setStepData(tempStepData);
        let filteredFiles = filesArray.filter((searchedFile) => searchedFile.fileInputName !== fileData.fileInputName);
        filteredFiles.push(fileData);
        setFilesArray(filteredFiles);
    };

    const getCurrentStep = () => {
        return isActive == "upload" ? (
            <FieldGroup title="Vehicle Finance Information">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <p className={styles.question}>Please upload a picture of your driving license</p>
                    <FieldContainer>
                        <Field extraStyle={{ padding: "0", margin: "0 auto" }} fieldLabel="" labelPosition="left">
                            <InputFile text={``} niceName={``} fileInputName={`poid`} attachFileToForm={attachFile} />
                        </Field>
                        {/* <Field extraStyle={{ padding: "0" }} fieldLabel="Finance Agreement " labelPosition="left">
                            <InputFile text={``} niceName={``} fileInputName={`financeAgreement`} attachFileToForm={attachFile} />
                        </Field> */}
                    </FieldContainer>
                    <SubmitButton text="Next" btnType="primary" onClick={() => animateBeforeAssign("complete")} />
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        );
    };

    return getCurrentStep();
};
