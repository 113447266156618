import React, { useEffect, useState } from "react";
// import styles from "./styles.module.scss";
import { Report } from "notiflix/build/notiflix-report-aio";

import { titleData } from "../../../data/titles";
import { contactPreferencesData } from "../../../data/contactPreferences";
import { InputFieldText, InputFieldSelect, FieldGroup, Field, SubmitButton, ButtonContainer, FieldContainer, FormCard } from "../../reusable";

import { FormPartInterface, SaveValueInterface } from "../../../interfaces";
import { Checkbox } from "../../reusable/checkbox";
import { InputFieldAddressLookup } from "../../reusable/inputFieldAddressLookup";
import { lenderData } from "../../../data/lenders";
import { SignaturePad } from "../../reusable/sigPad";
import { sectionValidation } from "../../../helpers";
export const ContactPreferences = (props: FormPartInterface) => {
    const { saveValueFunct, isActive, assignGroup, validateSection } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [postCurrentStep, setPostCurrentStep] = useState(0);
    const [stepData, setStepData] = useState<any>({});

    const preSaveValueFunct = (dataToSave: SaveValueInterface) => {
        const saved = saveValueFunct(dataToSave);
        let tempStepData = stepData;
        tempStepData[dataToSave.field] = dataToSave.value;
        setStepData(tempStepData);
        return saved;
    };

    const animateBeforeAssign = (assignment: string) => {
        console.log(stepData);

        setTimeout(() => {
            assignGroup(assignment);
        }, 400);
    };

    const getCurrentStep = () => {
        return isActive == "contactPreferences" ? (
            <FieldGroup title="Sign Your Letter of Authority">
                <FormCard assignPostStep={setPostCurrentStep} step={currentStep}>
                    <div>
                        <p>In accordance with GDPR, Car Claims Direct requires your permission to contact you. Please select your preferred</p>
                        <Checkbox fieldName={"SMS"} label={"SMS"} saveValue={preSaveValueFunct} />
                        <Checkbox fieldName={"Phone"} label={"Phone"} saveValue={preSaveValueFunct} />
                        <Checkbox fieldName={"E-mail"} label={"E-mail"} saveValue={preSaveValueFunct} />
                        <Checkbox fieldName={"WhatsApp"} label={"WhatsApp"} saveValue={preSaveValueFunct} />
                    </div>
                    <br />
                    <div>
                        <p>
                            Car Claims Direct works with partners to provide products and sevices that may benefit its clients. Please select the
                            products you are happy for us to contact you about:
                        </p>
                        <Checkbox fieldName={"energySaving"} label={"Energy Saving"} saveValue={preSaveValueFunct} />
                        <Checkbox fieldName={"boilerUpgrades"} label={"Boiler Upgrades"} saveValue={preSaveValueFunct} />
                        <Checkbox fieldName={"lifeInsurance"} label={"Life Insurance"} saveValue={preSaveValueFunct} />
                        <Checkbox fieldName={"other"} label={"Other"} saveValue={preSaveValueFunct} />
                        <Checkbox fieldName={"debtHelp"} label={"Debt Help"} saveValue={preSaveValueFunct} />
                        <Checkbox fieldName={"insuranceQuotes"} label={"InsuranceQuotes"} saveValue={preSaveValueFunct} />
                    </div>
                    <SubmitButton text="Next" btnType="primary" onClick={() => animateBeforeAssign("upload")} />
                </FormCard>
            </FieldGroup>
        ) : (
            <></>
        );
    };

    return getCurrentStep();
};
