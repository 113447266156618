import styles from "./styles.module.scss";
import { ButtonContainer, CtaButton, Wrapper } from "../../reusable";
import responsiveStyles from "./responsiveStyles.module.scss";
import { IsMobile } from "../../layout";

function KnowAboutPCP() {
    return (
        <div className={`${styles.knowAboutPcp} ${responsiveStyles.knowAboutPcp}  `}>
            <Wrapper>
                <div className={`${styles.twoCols} ${responsiveStyles.twoCols}`}>
                    <div>
                        <h2>What you need to know about PCP Agreements</h2>
                        {IsMobile() ? <></> : <CtaButton text="Start Claim" />}
                    </div>
                    {/* <div className={styles.lines}>
                        <img src={lines} />
                    </div> */}

                    <div className={`${styles.facts} ${responsiveStyles.facts}`}>
                        <div className={`${styles.fact} ${responsiveStyles.fact}`}>
                            <div className={`${styles.innerFact} ${responsiveStyles.innerFact}`}>
                                <p>Fact 1</p>
                                <h3>Unclear Terms</h3>
                                <p>The customer was not made aware of the potential early repayment charges.</p>
                            </div>
                        </div>
                        <div className={`${styles.fact} ${responsiveStyles.fact}`}>
                            <div className={`${styles.innerFact} ${responsiveStyles.innerFact}`}>
                                <p>Fact 2</p>
                                <h3>Misrepresented Payments</h3>
                                <p>The monthly payment was misrepresented or higher than initially agreed upon.</p>
                            </div>
                        </div>
                        <div className={`${styles.fact} ${responsiveStyles.fact}`}>
                            <div className={`${styles.innerFact} ${responsiveStyles.innerFact}`}>
                                <p>Fact 3</p>
                                <h3>Early Repayment Risks</h3>
                                <p>The customer was not made aware of the early repayment charges.</p>
                            </div>
                        </div>
                        <div className={`${styles.fact} ${responsiveStyles.fact}`}>
                            <div className={`${styles.innerFact} ${responsiveStyles.innerFact}`}>
                                <p>Fact 4</p>
                                <h3>Hidden Vehicle Costs</h3>
                                <p>The customer was not informed of the total cost of the vehicle after the end of the agreement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
}

export default KnowAboutPCP;
