import React from "react";
import logo from "./logo.svg";
import "./App.css";
import AppRouter from "./router";
import { ClaimProvider } from "./claimProvider";
import Hotjar from "@hotjar/browser";

function App() {
    const siteId = 3783967;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);

    return (
        <ClaimProvider>
            <div className="App">
                <AppRouter />
            </div>
        </ClaimProvider>
    );
}

export default App;
