import styles from "../globals/pages.module.scss";

import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { apiErrors } from "../helpers/apiErrors";
import ClaimForm from "../components/claimForm/main";
import { browserName, osName, deviceDetect, getUA } from "react-device-detect";
import UploadForm from "../components/uploadForm/main";
import { useUpdateClaimData } from "../claimProvider";

function UploadDocument() {
    const [resumeClaimData, setResumeClaimData] = useState<any>();
    const [requestingClaim, setRequestingClaim] = useState(false);
    const { token } = useParams();
    const updateClaim = useUpdateClaimData();

    useEffect(() => {
        if (!requestingClaim) {
            setRequestingClaim(true);
            axios
                .post(`${process.env.REACT_APP_API_RESUME_CLAIM}`, { token })
                .then((response) => {
                    const resumeData = response.data;
                    console.log("resumeData:", resumeData);
                    if (resumeData.success) {
                        setResumeClaimData(resumeData);
                        updateClaim(resumeData.claimData);
                    } else {
                        throw new Error("failed....");
                    }
                    // Loading.remove();
                })
                .catch((e) => {
                    console.log("error:", e);
                    Loading.remove();
                    if (e.response) {
                        const serverError = e.response.data;
                        apiErrors(serverError);
                    } else {
                        apiErrors("unknown");
                    }
                });
        }
    }, [requestingClaim]);

    return (
        <div className={`${styles.pcpClaimFormContainer}`}>
            <UploadForm />
        </div>
    );
}

export default UploadDocument;
